<template>
  <div class="p-10">
    <cgv></cgv>
  </div>
</template>
<script>
const cgv = () => import("./cgv.vue");

export default {
  components: {
    cgv
  }
};
</script>
<style>
h1,
h2 {
  display: block !important;
  margin: 15px auto 5px;
}
</style>
